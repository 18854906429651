@charset "UTF-8";

@import url("../../node_modules/normalize.css/normalize.css");

// Hamburger
$hamburger-padding-x           : 0;
$hamburger-padding-y           : 0;
$hamburger-layer-width         : 24px;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 4px;
$hamburger-layer-color         : #fff;
$hamburger-layer-border-radius : 0;
$hamburger-hover-opacity       : 1;
$hamburger-types: (
	spring,
);
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";
.hamburger {
	height: 25px;
}
.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::after, .hamburger.is-active .hamburger-inner::before {
    background-color: #fff;
}
.hamburger.is-active .hamburger-box{

    }
// @import url("../../node_modules/select2/dist/css/select2.min.css");
// @import url("../../node_modules/jquery-modal/jquery.modal.min.css");
@import url("../../node_modules/swiper/css/swiper.min.css");
